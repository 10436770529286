import { Helmet } from 'react-helmet';
import { Backdrop, Box, CircularProgress, Container,
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { blue } from '@mui/material/colors';
import APIData from '../../../utils/APIData';
import Services from '../../../utils/Services';
import { CookieUtils } from '../../../utils/UtilFunctions';
import { REPORTS_FEATURES, validateFeature } from '../../../utils/FeatureValidator';
// import CustomDatagrid from '../components/reports/CustomDatagrid';

const StockReport = () => {
  const [stockData, setStockData] = useState(undefined);
  const [shopNameList, setShopNameList] = useState([]);
  const [markNameList, setMarkNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const snackBarStatusDefault = {
    open: false,
    severity: 'info',
    message: ''
  };
  const APIToken = {
    GET_STOCK_REPORT: 'PSR01',
    GET_USER: 'PSR02'
  };
  const [snackBarStatus, setSnackBarStatus] = useState(snackBarStatusDefault);
  const handleSnackBarClose = () => {
    setSnackBarStatus(snackBarStatusDefault);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStockReport && apiToken == APIToken.GET_STOCK_REPORT ) {
      convertAndSetStockData(response.data);
      setIsLoading(false);
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'REPORTS');
        if (filtered != undefined && filtered.length > 0) {
          const isAllowed = validateFeature(filtered[0].available_features, REPORTS_FEATURES.VIEW_STOCK);
          setIsViewAccessGranted(isAllowed);
          if (!isAllowed) {
            showSnackBar('error', 'You don\'t have permission to view stock details');
            setIsLoading(false);
          } else {
            getStockData();
          }
        }
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getStockReport && apiToken == APIToken.GET_STOCK_REPORT) {
      defaultMsg = 'Failed to fetch stock data';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const sortShopNames = (data) => {
    data = data.filter((x) => x != 'ALL');
    // data.sort();
    data.push('ALL');
    return data;
  };

  const sortMarkNames = (data) => {
    data = data.filter((x) => x != 'TOTAL');
    // data.sort();
    data.push('TOTAL');
    return data;
  };

  const convertAndSetStockData = (data) => {
    const result = {};
    const shopNames = [];
    const markNames = [];
    data.map((item) => {
      const shopName = item.shop_name;
      item.stock_list.map((markData) => {
        const markName = markData.mark_name;
        if (result[markName] == undefined) {
          result[markName] = {};
        }
        result[markName][shopName] = {};
        result[markName][shopName].quantity = markData.quantity;
        result[markName][shopName].stock_value = markData.stock_value.display;
        if (!markNames.includes(markName)) {
          markNames.push(markName);
        }
      });
      if (!shopNames.includes(shopName)) {
        shopNames.push(shopName);
      }
    });
    setShopNameList(sortShopNames(shopNames));
    setMarkNameList(sortMarkNames(markNames));
    setStockData(result);
  };

  const getStockData = () =>{
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getStockReport },
      APIToken.GET_STOCK_REPORT, processSuccess, processError);
  };

  const getValue = (markName, shopName, fontwgt) => {
    let fontWt = fontwgt;
    if (shopName=='ALL') {fontWt=500;}
    return (
      <React.Fragment key={markName + shopName}>
        <TableCell align="center" colSpan={1} sx={{ borderLeft: 5, borderLeftColor: 'grey.300', fontWeight: fontWt }}>
          {stockData[markName][shopName]!=undefined ? stockData[markName][shopName].quantity : '-'}
        </TableCell>
        <TableCell align="center" colSpan={1} sx={{ fontWeight: fontWt }}>
          {stockData[markName][shopName]!=undefined ? stockData[markName][shopName].stock_value : '-'}
        </TableCell>
      </React.Fragment>
    );
  };

  const stockNameChecker = (item, index) => {
    let fontWt='normal';
    if (item=='TOTAL') {fontWt=500;}
    return (
      <TableRow key={index}>
        <TableCell align="center" colSpan={1} sx={{ fontWeight: fontWt }}>
          {item}
        </TableCell>
        {(shopNameList).map((item1, i) => (getValue(item, item1, fontWt)))}
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Stock Report | Rajmahal Apps</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: 'calc(100vh - 98px)'
        }}
      >
        {isViewAccessGranted ? (
          <Container maxWidth={false}>
            <Grid item xs={12} justifyContent='center' display='flex'>
              <Typography
                color="textPrimary"
                variant="h5">
                Stock Report
              </Typography>
            </Grid>
            <Grid
              mt={2}
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Paper elevation={2}>
                {stockData != undefined ?
                  <TableContainer sx={{ maxHeight: '90vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={1}/>
                          {shopNameList.map((item) => (
                            <TableCell key={item} align="center" colSpan={2}
                              sx={{ borderLeft: 5, borderLeftColor: 'grey.300', backgroundColor: '#ffffff',
                                borderBottom: 2, borderBottomColor: 'grey.300', fontSize: '14px' }}>
                              {item}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow sx={{ position: 'sticky' }}>
                          <TableCell align="center" colSpan={1} sx={{ backgroundColor: '#ffffff' }}>
                            Mark Name
                          </TableCell>
                          {shopNameList.map((item, i) => (
                            <React.Fragment key={i}>
                              <TableCell align="center" colSpan={1} sx={{ borderLeft: 5, borderLeftColor: 'grey.300', backgroundColor: '#ffffff' }}>
                                Quantity
                              </TableCell>
                              <TableCell align="center" colSpan={1} sx={{ backgroundColor: '#ffffff' }}>
                                Amount
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {markNameList.map((item, index) => (
                          stockNameChecker(item, index)
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> : '' }
              </Paper>
            </Grid>
          </Container>
        ) : ''}
      </Box>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default StockReport;
