import { Backdrop, CircularProgress, Container, Grid, Table, TableBody, Paper,
  TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Tooltip, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Utils } from '../../../../utils/UtilFunctions';
import Services from '../../../../utils/Services';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import APIData from '../../../../utils/APIData';
import { ANALYSIS_COMPONENTS } from '../../../../utils/EnumDefinitions';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PlanOrders = ({ planId, onSubmit, onBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const APIToken ={
    GET_ORDER_DETAILS: 'CPO01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raisePlanOrderList(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanOrdersForAnalysis && apiToken == APIToken.GET_ORDER_DETAILS ) {
      if (response.data != undefined) {
        setOrderList(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanOrdersForAnalysis && apiToken == APIToken.GET_ORDER_DETAILS) {
      defaultMsg = 'Failed to retrieve order list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raisePlanOrderList = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanOrdersForAnalysis, params: params },
      APIToken.GET_ORDER_DETAILS, processSuccess, processError);
  };

  const handleOrderIdClick = (selectedOrder) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.order_details,
      order_id: selectedOrder.order_id
    };
    onSubmit(obj);
  };

  const getPlanData = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell>Supplier Name</TableCell>
              <TableCell>Planned Qty</TableCell>
              <TableCell>Bought Qty</TableCell>
              <TableCell>% Covered </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Tooltip title='View Plan by Order'>
                    <Typography variant='h6' color='blue'
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => handleOrderIdClick(item)}
                    >
                      {item.order_id}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>{item.ac_name}</TableCell>
                <TableCell>{item.planned_quantity}</TableCell>
                <TableCell>{item.bought_quantity}</TableCell>
                <TableCell>{item.percent_covered}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              <ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Plan Orders
          </Typography>
        </Grid>
        {orderList.length > 0 ? (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Plan Id"
                label="Plan Id"
                name="plan_id"
                autoComplete ='off'
                value={planId}
                size='small'
                fullWidth
                variant='standard'
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              {getPlanData()}
            </Grid>
          </React.Fragment>
        ) : '' }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

PlanOrders.propTypes = {
  planId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default PlanOrders;

