import { Backdrop, Button, CircularProgress, Container, Grid, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { blue } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { cloneDeep } from 'lodash';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import { REPORTS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';

const StockLimit = () => {
  const [stockLimit, setStockLimit] = useState([]);
  const [totalStockAmount, setTotalStockAmount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [originalValue, setOriginalValue] = useState([]);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const [isModifyAccessGranted, setIsModifyAccessGranted] = useState(false);
  const APIToken = {
    GET_STOCK_LIMIT: 'CSL01',
    MODIFY_STOCK_LIMIT: 'CSL02',
    GET_USER: 'CSLB03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStockLimit && apiToken == APIToken.GET_STOCK_LIMIT ) {
      if (response.data != undefined) {
        setStockLimit(response.data.stock_details);
        setTotalStockAmount(response.data.total);
        setOriginalValue(response.data.stock_details);
      }
      setIsLoading(false);
    } else if (apiData == APIData.modifyStockLimit && apiToken == APIToken.MODIFY_STOCK_LIMIT ) {
      showSnackBar('success', response.message ?? 'Stock Limit updated successfully');
      setIsEditModeOn(false);
      raiseGetStockLimitRequest();
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'REPORTS');
        if (filtered != undefined && filtered.length > 0) {
          const isViewAllowed = validateFeature(filtered[0].available_features, REPORTS_FEATURES.VIEW_STOCK);
          const isModifyAllowed = validateFeature(filtered[0].available_features, REPORTS_FEATURES.MANAGE_STOCK);
          setIsViewAccessGranted(isViewAllowed);
          setIsModifyAccessGranted(isModifyAllowed);
          if (!isViewAllowed) {
            showSnackBar('error', 'You don\'t have permission to view stock details');
            setIsLoading(false);
          } else {
            raiseGetStockLimitRequest();
          }
        }
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getStockLimit && apiToken == APIToken.GET_STOCK_LIMIT) {
      defaultMsg = 'Failed to retrieve stock limit';
    } else if (apiData == APIData.modifyStockLimit && apiToken == APIToken.MODIFY_STOCK_LIMIT ) {
      defaultMsg = 'Failed to update stock limit';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetStockLimitRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getStockLimit },
      APIToken.GET_STOCK_LIMIT, processSuccess, processError );
  };

  const raiseModifyStockLimitRequest = (params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyStockLimit, params: params },
      APIToken.MODIFY_STOCK_LIMIT, processSuccess, processError );
  };

  const toggleEditMode = () => {
    setIsEditModeOn((prev) => !prev);
  };

  const handleStockLimitChange = (e, index) => {
    const { value } = e.target;

    if (validateStringForNull(value) && !validateNumber(value)) {
      return;
    }

    setStockLimit((prev) => {
      const updatedList = prev.map((item, idx) =>
        idx === index ? {
          ...item,
          stock_limit: {
            ...item.stock_limit,
            value: value === '' ? 0 : Number(value)
          }
        } : item
      );

      const newTotalStockValue = updatedList.reduce((sum, item) => sum + (item.stock_limit.value || 0), 0);

      setTotalStockAmount((prevTotal) => ({
        ...prevTotal,
        stock_limit: {
          value: newTotalStockValue,
          display: new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
          }).format(newTotalStockValue)
        }
      }));

      return updatedList;
    });
  };

  const validateParams = (stockLimit) => {
    const changedValues = stockLimit
      .filter((item, index) =>{
        const value = item.stock_limit.value;
        if (!validateStringForNull(value)) {
          throw new Error(`Provide an valid stock limit for mark ${item.mark_name}`);
        }
        return value !== originalValue[index].stock_limit.value;
      })
      .map((item) => ({
        item_id: item.item_id,
        amount: item.stock_limit.value
      }));

    return { stock_details: changedValues };
  };

  const handleUpdateStockLimit = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(stockLimit);
      const filteredParams = validateParams(params);
      if (filteredParams.stock_details.length == 0) {
        showSnackBar('warning', 'Nothing to Update');
        setIsLoading(false);
        return;
      }
      raiseModifyStockLimitRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      {stockLimit.length > 0 && isViewAccessGranted ? (
        <Grid container direction='row' columnSpacing={2} rowSpacing={2} justifyContent='center' sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <Grid item container xs={12}>
              <Grid item xs={11} display='flex' justifyContent='center' alignItems='center'>
                <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                  Stock Limit
                </Typography>
              </Grid>
              {isModifyAccessGranted ? (
                <Grid item xs={1} display='flex' justifyContent='flex-end'>
                  <IconButton onClick={toggleEditMode}>
                    {isEditModeOn ? <CancelIcon /> :
                      <EditIcon color= 'primary' />}
                  </IconButton>
                </Grid>
              ) : ''}
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Mark Name</TableCell>
                    <TableCell align="center" sx={{ minWidth: 100 }}>Effective Date</TableCell>
                    <TableCell align="center">Current Stock</TableCell>
                    <TableCell align="left" sx={{ minWidth: 150 }}>Stock Limit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockLimit.map((item, index) => (
                    <TableRow key={item.item_id}>
                      <TableCell align="center">{item.mark_name}</TableCell>
                      <TableCell align="center" sx={{ minWidth: 100 }}>{item.eff_date != undefined ? item.eff_date.display : ''}</TableCell>
                      <TableCell align="center">{item.current_stock.display}</TableCell>
                      <TableCell align="left" >
                        {isEditModeOn ? (
                          <TextField
                            name="stock_limit"
                            value={item.stock_limit.value}
                            onChange={(e) => handleStockLimitChange(e, index)}
                            type="number"
                            fullWidth
                            size='small'
                          />
                        ) : (
                          item.stock_limit.display
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="center">
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight="bold">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight="bold">
                        {totalStockAmount.current_stock != undefined ? totalStockAmount.current_stock.display : '' }
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontWeight="bold">
                        {totalStockAmount.stock_limit != undefined ? totalStockAmount.stock_limit.display : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {stockLimit.length != 0 && isEditModeOn ? (
            <Grid item xs={12} display='flex' alignItems='flex-end' justifyContent='flex-end'>
              <Button
                type="submit"
                variant='contained'
                onClick={handleUpdateStockLimit}
              >
                Update
              </Button>
            </Grid>
          ) : ''}
        </Grid>
      ) : ''}
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default StockLimit;
